@mixin unstyledList {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin unstyledButton {
  display: inline-block;
  padding: 0;
  border: 0;
  background: none;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.25;
  font-weight: inherit;
  text-decoration: none;
  color: inherit;
  appearance: none;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &::-moz-focus-inner {
    border: 0;
  }
}
