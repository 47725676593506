.root {
  min-height: 100%;
  display: flex;
  align-items: center;
  padding: 40px 20px;
}

.logo {
  margin-bottom: 20px;
}
