.root {
  min-height: 100%;
}

.logo {
  display: flex;
  align-items: center;
  height: 64px;
  margin-bottom: -4px;
  padding: 0 24px;
}

.header {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  padding: 0 12px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(#000, 0.1);
}

.avatar {
  margin-right: 8px;
}

.content {
  min-height: 280px;
  margin: 0 24px 0;
  padding: 24px;
  background: #fff;
}

.footer {
  text-align: center;
}
