.root {
  display: inline-block;
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
}

.inverted {
  color: #fff;
}
