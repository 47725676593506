@import '../styles/variables';
@import '../styles/mixins';

.root {
  @include unstyledButton;
  height: 100%;
  padding: 0 12px;
  cursor: pointer;
  transition: $transition;

  &:hover {
    background: #f5f5f5;
  }
}

.avatar {
  margin-right: 8px;
}

.icon {
  margin-right: 8px;
}

.arrow {
  margin-left: 6px;
  vertical-align: -2px;
  font-size: 12px;
}
