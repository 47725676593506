:global {
  #root,
  #root > [tabindex="-1"] {
    height: 100%;
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
